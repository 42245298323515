<template>
<div id="carouselExampleIndicators" class="carousel slide hero-carousel" data-ride="carousel">
  <ol class="carousel-indicators">
    <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
    <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
    <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
  </ol>
  <div class="carousel-inner">
    <div class="carousel-item" :class="{ active: index === 0 }" v-for="(publish, index) in carousel.slice(0,3)" :key="index">
      <div class="carousel-image">
        <img class="car_img" :src="publish.carsoul_img" alt="">
          <div class="carousel-caption" style="text-align: right;">
            <h2 class="animated fadeInLeft fonto" >{{ publish.title_ar }}</h2>
            <p class="animated fadeInRight" style="margin-bottom: 5px;font-size: 14px;">{{ publish.desc_ar }}</p>
            <a class="animated fadeInUp btn delicious-btn" @click="getFeed(publish)">مشاهدة</a>
          </div>
      </div>
    </div>
  </div>
  <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="sr-only">Previous</span>
  </a>
  <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="sr-only">Next</span>
  </a>
</div>
<!-- Videos Style 1 -->
<section class="gen-section-padding-3 upper_margin">
       <div class="pc-tab">
    <input checked="checked" id="tab0" type="radio" name="pct" />
    <template v-for="publish in categorie.slice(0,3)" :key="publish.id">
        <input :id="'tab'+publish.tab" type="radio" name="pct"/>
    </template>
    <nav>
      <ul  class="slider-radio justify">
      <li class="tab0">
          <label  for="tab0">
            <svg class="svg-icon" viewBox="0 0 20 20" style="margin-right: 0px; width: 17px; margin-left: 5px;"><path fill="#fff" d="M7.228,11.464H1.996c-0.723,0-1.308,0.587-1.308,1.309v5.232c0,0.722,0.585,1.308,1.308,1.308h5.232
                                                c0.723,0,1.308-0.586,1.308-1.308v-5.232C8.536,12.051,7.95,11.464,7.228,11.464z M7.228,17.351c0,0.361-0.293,0.654-0.654,0.654
                                                H2.649c-0.361,0-0.654-0.293-0.654-0.654v-3.924c0-0.361,0.292-0.654,0.654-0.654h3.924c0.361,0,0.654,0.293,0.654,0.654V17.351z
                                                M17.692,11.464H12.46c-0.723,0-1.308,0.587-1.308,1.309v5.232c0,0.722,0.585,1.308,1.308,1.308h5.232
                                                c0.722,0,1.308-0.586,1.308-1.308v-5.232C19,12.051,18.414,11.464,17.692,11.464z M17.692,17.351c0,0.361-0.293,0.654-0.654,0.654
                                                h-3.924c-0.361,0-0.654-0.293-0.654-0.654v-3.924c0-0.361,0.293-0.654,0.654-0.654h3.924c0.361,0,0.654,0.293,0.654,0.654V17.351z
                                                M7.228,1H1.996C1.273,1,0.688,1.585,0.688,2.308V7.54c0,0.723,0.585,1.308,1.308,1.308h5.232c0.723,0,1.308-0.585,1.308-1.308
                                                V2.308C8.536,1.585,7.95,1,7.228,1z M7.228,6.886c0,0.361-0.293,0.654-0.654,0.654H2.649c-0.361,0-0.654-0.292-0.654-0.654V2.962
                                                c0-0.361,0.292-0.654,0.654-0.654h3.924c0.361,0,0.654,0.292,0.654,0.654V6.886z M17.692,1H12.46c-0.723,0-1.308,0.585-1.308,1.308
                                                V7.54c0,0.723,0.585,1.308,1.308,1.308h5.232C18.414,8.848,19,8.263,19,7.54V2.308C19,1.585,18.414,1,17.692,1z M17.692,6.886
                                                c0,0.361-0.293,0.654-0.654,0.654h-3.924c-0.361,0-0.654-0.292-0.654-0.654V2.962c0-0.361,0.293-0.654,0.654-0.654h3.924
                                                c0.361,0,0.654,0.292,0.654,0.654V6.886z"></path></svg>
           كل المحتوي</label>
        </li>
        <template v-for="publish in categorie.slice(0,3)" :key="publish.id">
           <li :class="'tab'+publish.tab" @click="gettab(publish)">
            
              <label :for="'tab'+publish.tab"> <svg class="svg-icon" viewBox="0 0 20 20" style="margin-right: 0px; width: 17px; margin-left: 5px;"><path fill="#fff" d="M7.228,11.464H1.996c-0.723,0-1.308,0.587-1.308,1.309v5.232c0,0.722,0.585,1.308,1.308,1.308h5.232
                                                c0.723,0,1.308-0.586,1.308-1.308v-5.232C8.536,12.051,7.95,11.464,7.228,11.464z M7.228,17.351c0,0.361-0.293,0.654-0.654,0.654
                                                H2.649c-0.361,0-0.654-0.293-0.654-0.654v-3.924c0-0.361,0.292-0.654,0.654-0.654h3.924c0.361,0,0.654,0.293,0.654,0.654V17.351z
                                                M17.692,11.464H12.46c-0.723,0-1.308,0.587-1.308,1.309v5.232c0,0.722,0.585,1.308,1.308,1.308h5.232
                                                c0.722,0,1.308-0.586,1.308-1.308v-5.232C19,12.051,18.414,11.464,17.692,11.464z M17.692,17.351c0,0.361-0.293,0.654-0.654,0.654
                                                h-3.924c-0.361,0-0.654-0.293-0.654-0.654v-3.924c0-0.361,0.293-0.654,0.654-0.654h3.924c0.361,0,0.654,0.293,0.654,0.654V17.351z
                                                M7.228,1H1.996C1.273,1,0.688,1.585,0.688,2.308V7.54c0,0.723,0.585,1.308,1.308,1.308h5.232c0.723,0,1.308-0.585,1.308-1.308
                                                V2.308C8.536,1.585,7.95,1,7.228,1z M7.228,6.886c0,0.361-0.293,0.654-0.654,0.654H2.649c-0.361,0-0.654-0.292-0.654-0.654V2.962
                                                c0-0.361,0.292-0.654,0.654-0.654h3.924c0.361,0,0.654,0.292,0.654,0.654V6.886z M17.692,1H12.46c-0.723,0-1.308,0.585-1.308,1.308
                                                V7.54c0,0.723,0.585,1.308,1.308,1.308h5.232C18.414,8.848,19,8.263,19,7.54V2.308C19,1.585,18.414,1,17.692,1z M17.692,6.886
                                                c0,0.361-0.293,0.654-0.654,0.654h-3.924c-0.361,0-0.654-0.292-0.654-0.654V2.962c0-0.361,0.293-0.654,0.654-0.654h3.924
                                                c0.361,0,0.654,0.292,0.654,0.654V6.886z"></path></svg> {{ publish.name_ar }}</label>
           </li>
        </template>
      </ul>
      
      
    </nav>

    <section style="padding: 0 0px 130px 0px;">
    <div class="tab0">
      <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="row">
                        <div class="col-xl-3 col-lg-4 col-md-6" v-for="publish in carousel" :key="publish.id">
                            <div class="gen-carousel-movies-style-1 movie-grid style-1">
                                <div class="gen-movie-contain" @click="getFeed(publish)">
                                  <div class="gen-movie-img" >
                                    <a  @click="getFeed(publish)" >
                                        <img v-lazy="publish.carsoul_img" alt="single-video-image">
                                        
                                        
                                        <div class="gen-movie-action">
                                            <div  class="gen-button">
                                                <svg fill="#fff" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26">
                                                <polygon  points="9.33 6.69 9.33 19.39 19.3 13.04 9.33 6.69"/>
                                                <path fill="none" d="M26,13A13,13,0,1,1,13,0,13,13,0,0,1,26,13ZM13,2.18A10.89,10.89,0,1,0,23.84,13.06,10.89,10.89,0,0,0,13,2.18Z"/>
                                                </svg> 
                                            </div>
                                        </div>
                                        </a>
                                        <div class="gen-info-contain">
                                        <div class="gen-movie-info">
                                            <h3><a @click="getFeed(publish)">{{publish.title_ar}}</a></h3>
                                        </div>
                                        <div class="gen-movie-meta-holder">
                                            <ul>
                                                <li>{{publish.year}}</li>
                                                <li>
                                                    <a @click="getFeed(publish)"><span>{{publish.name_ar}}</span></a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
      <template v-for="publish in categorie.slice(0,3)" :key="publish.id">
      <div :class="'tab'+publish.tab">
      <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="row">
                        <div class="col-xl-3 col-lg-4 col-md-6" v-for="publish in cateData" :key="publish.id">
                            <div class="gen-carousel-movies-style-1 movie-grid style-1">
                                <div class="gen-movie-contain" @click="getFeed(publish)">
                                  <div class="gen-movie-img" >
                                    <a  @click="getFeed(publish)" >
                                        <img v-lazy="publish.carsoul_img" alt="single-video-image">
                                        
                                        
                                        <div class="gen-movie-action">
                                            <div  class="gen-button">
                                                <svg fill="#fff" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26">
                                                <polygon  points="9.33 6.69 9.33 19.39 19.3 13.04 9.33 6.69"/>
                                                <path fill="none" d="M26,13A13,13,0,1,1,13,0,13,13,0,0,1,26,13ZM13,2.18A10.89,10.89,0,1,0,23.84,13.06,10.89,10.89,0,0,0,13,2.18Z"/>
                                                </svg> 
                                            </div>
                                        </div>
                                        </a>
                                        <div class="gen-info-contain">
                                        <div class="gen-movie-info">
                                            <h3><a @click="getFeed(publish)">{{publish.title_ar}}</a></h3>
                                        </div>
                                        <div class="gen-movie-meta-holder">
                                            <ul>
                                                <li>{{publish.year}}</li>
                                                <li>
                                                    <a @click="getFeed(publish)"><span>{{publish.name_ar}}</span></a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
     </template>
    </section>
  </div>
        
    </section>
</template>
<script>
import { HTTP } from '@/Api/http-common';
import { HTTPDSP } from '@/Api/http-dsp';
import { ref } from '@vue/reactivity';
import $ from 'jquery'
import { useRouter, useRoute } from "vue-router"
import { useCookie } from 'vue-cookie-next';
import { watchEffect } from '@vue/runtime-core';
import { useToast } from "vue-toastification";
// import Play from "@/components/SVG/Play.vue";
export default {
   name: 'AppHome',
   components: { 
      //  Play
       },
   setup() {
      const router = useRouter();
      const route = useRoute();
      const cookie = useCookie();
      const toast = useToast();
      const carousel = ref([]);
      const categorie = ref([]);
      const cateData = ref([]);

      watchEffect(() => {
        if (!cookie.isCookieAvailable("msisdn") && !cookie.isCookieAvailable("status")) {
          let urlParams = new URLSearchParams(window.location.search);
          // console.log(urlParams.get('msisdn'))
          if(urlParams.has('msisdn')) {
            HTTPDSP.post(`DSPCheckLogin.php?msisdn=`+urlParams.get('msisdn')).then((res) => {
                if (res.data.status == 1 ) {
                    cookie.setCookie('msisdn', urlParams.get('msisdn'), { expire: 60 * res.data.remming_minutes, })
                    cookie.setCookie('status', res.data.status, { expire: 60 * res.data.remming_minutes, })
                    cookie.setCookie('remming_minutes', res.data.remming_minutes, { expire: 60 * res.data.remming_minutes, })
                    toast.success("مرحبا بك معنا  ", { timeout: 1000 });
                    if(cookie.getCookie("content_id")!=null){
                      toast.info("سوف يتم تحويلك الي المحتوي", { timeout: 2000 });
                      setTimeout(() =>  router.push({name: "Contents", params: { id: cookie.getCookie("content_id") } }), 2000);
                    } else {
                      setTimeout(() => router.push({ path: "/" }), 2500);
                    }
                } else if (res.data.status == 0 ) {
                  cookie.removeCookie('msisdn');
                    toast.error("لست مشترك في هذة الخدمة", { timeout: 1000 });
                    setTimeout(() => router.push({ path: "/" }), 2500);
                }
                
                })
            }
        }
      });
        


      try {
        HTTP.get('GetAllCategories.php').then((res) => {
          categorie.value = res.data.Categories; 
        });
      } catch (err) {
          console.log(err);
      }
      const gettab = (publish) => {
        HTTP.get('GetContentByCategory.php?LIMIT=10&OFFSET=0&cat_id=' + publish.id).then((res) => {
            cateData.value = res.data.Content; 
        });
      }
      try {
         HTTP.get('GetAllContent.php?LIMIT=30&OFFSET=0').then((res) => {
            carousel.value = res.data.Content; 
        });
      } catch (err) {
        console.log(err);
      }
      const getFeed = (publish) => {
        cookie.setCookie('cat_id', publish.cat_id);
        if(cookie.isCookieAvailable("msisdn") && cookie.isCookieAvailable("status")) {
          router.push({ name: "Contents", params: { content_id: publish.id } });
        } else {
          cookie.setCookie('content_id', publish.id);
          router.push({ name: "Subscribes" });
        }
      };
      return { carousel,gettab,cateData,categorie, getFeed };
   },
   mounted() {
      (function($){
  
  $(document).ready(function(){
    // Pause hero carousel
    $('.hero-carousel').carousel('pause');
  });
  
});
   }
}
</script>


<style scoped>
.hero-carousel .carousel-item .carousel-image{
  transform: scale(1) translateX(0);
  transition: all 0.6s;
}

.hero-carousel .carousel-item.carousel-item-next .carousel-image,
.hero-carousel .carousel-item.active.carousel-item-right .carousel-image{
  transform: scale(1.3) translateX(-50%);
}

.hero-carousel .carousel-item.carousel-item-prev .carousel-image,
.hero-carousel .carousel-item.active.carousel-item-left .carousel-image{
  transform: scale(1.3) translateX(50%);
}

.hero-carousel .carousel-item.carousel-item-next.carousel-item-left .carousel-image, 
.hero-carousel .carousel-item.carousel-item-prev.carousel-item-right .carousel-image{
  /*transform: scale(1);*/
  transform: scale(1) translateX(0);
}


/* Makes carousel full screen */
.hero-carousel .carousel-item{
  height: 100vh;
  overflow: hidden;
}
.hero-carousel .carousel-item .carousel-image{
  height: 100vh;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}




/* Ignore this CSS */
#carouselExampleIndicators{
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.carousel-control-prev, .carousel-control-next {
  width: 0%;
}
.carousel-inner {
  height: 50vh;
}
@media(max-width:786px){

  .carousel-inner {
  height: 40vh;
}
.carousel-caption{
  padding-top: 7vh;
padding-left: 20vw;
}
}
.carousel-inner .carousel-item {
  transition: transform 1s ease;
}

.carousel-caption {
  position: absolute;
right: 0 !important;
top: 0;
color: white;
text-shadow: 1px 1px 2px rgba(2, 15, 19, 0.36);
font-family: 'Julius Sans One';
font-style: normal;
font-weight: 400;
font-size: 2.5vw;
transition: 1s ease;
background: #00000036;
padding: 40px;
  padding-top: 40px;
  padding-left: 40px;
color: white;
-webkit-animation-duration: 1s;
animation-duration: 1s;
-webkit-animation-delay: 2s;
animation-delay: 2s;
width: 100%;
padding-top: 150px;
padding-left: 20vw;
text-align: right;
}

.carousel-caption h2 {
  animation-duration: 1s;
  animation-delay: 2s;
}

.carousel-caption p {
  animation-duration: 1s;
  animation-delay: 2.2s;
}
.caption-top {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
  opacity: 1;
  transition: 1s ease;
}

.caption-top.slide {
  font-size: 2vw;
  top: -70%;
  opacity: 1;
}
.delicious-btn {
  /* display: inline-block; */
  display: inline-block;
min-width: 125px;
height: 46px;
color: #ffffff;
border: none;
  border-left-color: currentcolor;
  border-left-style: none;
  border-left-width: medium;
border-left-color: currentcolor;
border-left-style: none;
border-left-width: medium;
border-left: 3px solid var(--primary);
border-radius: 0;
padding: 0 30px;
font-size: 16px;
line-height: 46px;
font-weight: 600;
transition-duration: 500ms;
text-transform: capitalize;
background-color: var(--primary-color);
}
.caption-bottom {
  position: relative;
  top: 15%;
  left: 0;
  width: 100%;
  text-align: center;
  opacity: 1;
  transition: 1s ease;
}

.caption-bottom.slide {
  font-size: 2vw;
  top: 140%;
  opacity: 1;
}

@media (min-width: 993px) and (max-width: 1200px) {
  .carousel-inner .carousel-item {
    margin-top: 70px;
  }
}

@media (max-width: 992px) {
  .carousel-inner .carousel-item {
    margin-top: 52px;
  }
}

@media (max-width: 776px) {
  .carousel-caption {
    font-size: 5vw;
    padding-top:7vh;
  }
  .caption-top-slide,
  .caption-bottom-slide {
    font-size: 2vw;
  }
}
.play-btn {
  width: 25%;
  height: auto;
  margin: 0 auto;
  margin-top: 10%;
}

.play-btn__svg{
  transition: 1s; 
  fill:#7c7c7c; 
  opacity: .5;
}

.play-btn:hover .play-btn__svg {
  fill: #a41d33;
  opacity: 1;
}











.pointer {
  cursor: pointer;
}
@import url(https://fonts.googleapis.com/css?family=Droid+Serif:400,700);
@import url(https://fonts.googleapis.com/css?family=Raleway:100,400,700);
/* Component Needs */
.pc-tab > input,
.pc-tab section > div {
  display: none;
}
#tab0:checked ~ section .tab0,
#tab1:checked ~ section .tab1,
#tab2:checked ~ section .tab2,
#tab3:checked ~ section .tab3,
#tab4:checked ~ section .tab4,
#tab5:checked ~ section .tab5,
#tab6:checked ~ section .tab6 {
  display: block;
}
#tab0:checked ~ nav .tab0,
#tab1:checked ~ nav .tab1,
#tab2:checked ~ nav .tab2,
#tab3:checked ~ nav .tab3,
#tab4:checked ~ section .tab4,
#tab5:checked ~ section .tab5,
#tab6:checked ~ section .tab6 {
  color: red;
}
/* Visual Styles */
*,
*:after,
*:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

h1 {
  text-align: center;
  font-weight: 100;
  font-size: 60px;
  color: #e74c3c;
}
.pc-tab {
  width: 100%;
  max-width: 100%;
  height: fit-content;
  margin: 0 auto;
}
.pc-tab ul {
  list-style: none;
justify-content: center;
height: -webkit-fit-content;
height: -moz-fit-content;
height: fit-content;
padding-right: 0%;
padding-left: 0%;
background: transparent;
}
.pc-tab ul li label {
  display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
height: 43px;
padding: 0 10px;
font-size: 18px;
font-weight: 600;
font-family: "Cairo", sans-serif;
color: #fff;
background: #4A4468;
margin-bottom: 0;
cursor: pointer;
border-radius: 0;
margin: 0 0;
position: relative;
z-index: 100;
transition: color 0.5s;
border-left: 1px solid #0000006b;
}
.pc-tab ul li label:hover {
  background-color: #70679c;
}
.pc-tab ul li label:active {
  background-color: #70679c;
}
.pc-tab ul li:not(:last-child) label {
  border-right-width: 0;
}
.pc-tab section {
  font-family: "Droid Serif";
  clear: both;
}

.pc-tab section div h2 {
  margin: 0;
  font-family: "Raleway";
  letter-spacing: 1px;
  color: #34495e;
}
#tab0:checked ~ nav .tab0 label,
#tab1:checked ~ nav .tab1 label,
#tab2:checked ~ nav .tab2 label,
#tab3:checked ~ nav .tab3 label,
#tab4:checked ~ nav .tab4 label,
#tab5:checked ~ nav .tab5 label,
#tab6:checked ~ nav .tab6 label {
  color: #1d3544;
  background-color: #70679c;
  position: relative;
}
#tab0:checked ~ nav .tab0 label:after,
#tab1:checked ~ nav .tab1 label:after,
#tab2:checked ~ nav .tab2 label:after,
#tab3:checked ~ nav .tab3 label:after,
#tab4:checked ~ nav .tab4 label:after,
#tab5:checked ~ nav .tab5 label:after,
#tab6:checked ~ nav .tab6 label:after {
  content: "";
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: transparent;
  right: 0;
  bottom: -1px;
}





</style>